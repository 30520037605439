/* ==========================================================================
	Base
/* ========================================================================== */

html,
body,
#root {
  height: 100%;
}

body {
  min-width: 320px;

  font-family: $fontBase;
  font-size: $fontSizeBase;
  line-height: 1.4;
  color: $colorText;

  background: $colorBody;

  -webkit-font-smoothing: antialiased;
}

a {
  color: $colorText;
  text-decoration: none;
}

ul {
  padding: 0;

  margin: 0;

  list-style-type: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

[placeholder] {
  text-overflow: ellipsis;
}

input::-ms-clear {
  display: none;
}

/**
 * IOS will zoom focused inputs, unless font-size is bigger or equal 16px.
 * @see https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
 */
@supports (-webkit-overflow-scrolling: touch) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
