// Colors
$colorBody: #f7f9fa;
$colorText: #434f64;
$colorHeader: #202531;
$colorPrimary: #c12b2c;

// Fonts
$fontBase: 'Inter', sans-serif;
$fontSecondary: 'Roboto', sans-serif;
$fontSizeBase: 1em;
